// noinspection ES6UnusedImports
import React from 'react';
import {createRoot} from 'react-dom/client';
import MarketingParameters from '@consent/scripts/components/MarketingParameters.jsx';
import JshModule from "@core/scripts/helper/jsh-module";

const {moduleScope, config} = JshModule('consent/marketing-parameters');

createRoot(moduleScope).render(
    <MarketingParameters
        pageType={config.pageType}
        hurraScriptUrl={config.hurraScriptUrl}
        traceId={config.hurraTraceId}
    />
);

